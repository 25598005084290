/** @jsx jsx */
import { graphql } from "gatsby";
import { jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image";

type ProjectCardProps = {
  link: string
  title: string
  children: React.ReactNode,
  image: string,
  bg: string
}

const ProjectCard = ({ link, title, image, children, bg }: ProjectCardProps) => {
  return (
      <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      sx={{
        width: `100%`,
        boxShadow: `lg`,
        position: `relative`,
        textDecoration: `none`,
        borderRadius: `lg`,
        px: 4,
        pt: [0, 0, 4],
        pr: [0, 0, null],
        display: ['flex', 'flex', 'block'],
        alignItems: 'center',
        height: ['175px', '175px', '600px'],
        color: `white`,
        background: bg || `none`,
        transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
        "&:hover": {
          color: `white !important`,
          transform: `translateY(-5px)`,
          boxShadow: `xl`,
        },
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          pb: [0, 0, 20]
        }}
        >
        <div>
          <img sx={{
            height: ['50px', '75px', '100px'],
            alignSelf: "flex-end"
          }} src={image} />
        </div>
        <div
          sx={{
            letterSpacing: `wide`,
            pb: 0,
            pl: '20px',
            pr: ['20px', '20px', null],
            fontSize: [3, 4],
            fontWeight: `medium`,
            lineHeight: 1,
            height: '100%'
          }}
        >

          {title}
        </div>
      </div>


      <div sx={{
            visibility: ['hidden', 'hidden', 'visible'],
            height: ['0px', '0px', 'inherit'],
            width: ['0px', '0px', 'inherit'],
            opacity: 0.85,
            textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
            fontSize: [null, null, '1.1em'],
            pr: [null, null, 30]
          }}>
        {children}
      </div>
    </a>
  )
}



export default ProjectCard
